<template>
    <div id="app">
        <spinner-loader v-if="isLoading && !hideDashboard" />

        <div v-if="isAuthenticated && isAuthorized && !hideDashboard">
            <div class="o-layout o-layout--fluid">
                <legal-agreements-check />
                <div v-if="isUserImpersonated">
                    <impersonate-bar-component />
                </div>
                <header-component />
                <navigation-bar />
            </div>

            <div class="o-layout o-layout--fluid">
                <div class="py-4">
                    <router-view />
                </div>
            </div>

            <notification-panel v-if="enableNotificationPanel && !isAdministrator" />

            <recess-toast-multi @updateToastNotifications="removeFromToastList" :toast-list="toastNotifications" />
        </div>

        <access-denied v-else-if="isAuthenticated" :system-error-data="systemError" />
        <button @click="bot.open()"></button>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapActions } from 'vuex'
import wozzbot from 'wozzbot-sdk'
import { getItem } from '@/../../shared/api/SharedClient'
import { API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import LegalAgreementsCheck from '@/components/atoms/LegalAgreementsCheck'
import ImpersonateBarComponent from '@/components/molecules/ImpersonateBarComponent'
import HeaderComponent from '@/components/molecules/HeaderComponent'
import NavigationBar from '@/components/atoms/NavigationBar'
import SpinnerLoader from '@/components/atoms/SpinnerLoader'
import UserService from '../../shared/services/UserService'

import NotificationPanel from '@/components/molecules/NotificationPanel'

import AccessDenied from '../../shared/components/Shared/AccessDenied'

import edmundo from '@/assets/images/icons/Edmund_Chat_Avatar.svg'

export default {
    name: 'App',
    components: {
        LegalAgreementsCheck,
        ImpersonateBarComponent,
        HeaderComponent,
        NavigationBar,
        SpinnerLoader,
        NotificationPanel,
        AccessDenied
    },
    data() {
        return {
            API_CALL_URL_PATHS,
            userService: new UserService(),
            interval: null,
            isAuthenticated: false,
            isAuthorized: false,
            isUserImpersonated: false,
            isAdministrator: false,
            loadingCount: 0,
            isLoading: false,
            enableNotificationPanel: false,
            systemError: null,
            hideDashboard: true,
            isAccEnvironment: process.env.NODE_ENV === 'acceptance',
            isPrdEnvironment: process.env.NODE_ENV === 'production',
            bot: {}
        }
    },
    computed: {
        ...mapState('toastNotificationModule', ['toastNotifications']),
        showSystemErrors() {
            return this.systemError && this.systemError?.isEnabled && this.systemError?.message !== null
        }
    },
    async created() {        
        const user = await this.userService.getUser()

        this.isAuthenticated = user != null

        if (!this.isAuthenticated) {
            this.userService.signIn()

            return
        }

        this.isAuthorized = await this.userService.isAuthorizedInEduMSProvider()

        axios.interceptors.request.use(
            async config => {
                const isStartmomentCall = config.url.includes('/provider/v2/startMoments/search')
   
                if(isStartmomentCall && this.$route.query?.tab !== 'course-planning') {
                    this.setLoading(false)
                }else {
                    this.setLoading(true)
                }

                if (!this.isAuthenticated || user == null) {
                    throw new axios.Cancel('No user is authenticated.')
                } else {
                    config.headers.common.Authorization = `Bearer ${user.access_token}`
                    if(this.isAccEnvironment || this.isPrdEnvironment){
                        config.headers.common['Ocp-Apim-Subscription-Key'] = process.env.VUE_APP_SUBSCRIPTIONKEY
                    }
                    return config
                }
            },
            error => {
                this.setLoading(false)
                return Promise.reject(error)
            }
        )

        axios.interceptors.response.use(
            response => {
                this.setLoading(false)
                return response
            },
            error => {
                this.setLoading(false)

                if (error && error.response && error.response.status) {
                    if (error.response.status !== 400 || (error.response.status === 400 && error.response.data.error.modelState && error.response.data.error.modelState.id)) {
                        this.$router.replace({
                            name: 'NotFound',
                            params: { responseCode: error.response.status },
                            query: {}
                        })
                    }
                }

                return Promise.reject(error)
            }
        )

        await this.getMessage()
        if (this.showSystemErrors !== null && this.showSystemErrors) return


        this.isAdministrator = await this.userService.isAdministrator()

        if (!this.isAdministrator) {
            const providerId = await this.userService.getUserProviderId()

            if (providerId == null) {
                // TODO: user is misconfigured - show error page
                return
            }

            await this.fetchProvider(providerId)
        }

        this.isUserImpersonated = await this.userService.isUserImpersonated()

        this.enableNotificationPanel = true
        await this.getWhatsNew()
            this.bot = wozzbot({
                clientIdentifier: 'rrto6q5vvww9x2h1',
                icon: edmundo,
                ui: {
                    popupSentence: false,
                    closeFromMessagePane: true
                }
            })
    },
    beforeDestroy() {
        localStorage.removeItem('providerId')
        clearInterval(this.interval)
    },
    methods: {
        ...mapActions('providerModule', ['fetchProvider']),
        ...mapActions('toastNotificationModule', ['add', 'remove']),
        removeFromToastList(toastNotification) {
            this.remove(toastNotification)
        },
        async getMessage() {
            try {
                const response = await getItem(
                    process.env.VUE_APP_DEVOPS_API_URL,
                    API_CALL_URL_PATHS.systemInterruptionMessage,
                    false
                )

                if (!response) return
                this.systemError = response

            } catch (error) {
                console.error('Could not get system errors', error)
            }

            if (!this.showSystemErrors) {
                this.hideDashboard = false
            }
        },
        setLoading(isLoading) {
            if (isLoading) {
                this.loadingCount += 1
                this.isLoading = true
            } else if (this.loadingCount > 0) {
                this.loadingCount -= 1
                this.isLoading = this.loadingCount > 0
            }
        },
        async getWhatsNew() {
            try {
                const response = await getItem(
                    process.env.VUE_APP_DEVOPS_API_URL,
                    API_CALL_URL_PATHS.whatsNew
                )
                if (!response) return

                this.$bus.emit('trigger-notification-badge', response)

            } catch (error) {
                console.error('Something went wrong while retrieving the whats new', error)
            }
        }
    }
}
</script>

<style lang="scss">
@import './styles/main.scss';
</style>