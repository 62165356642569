import dayjs from 'dayjs'
import 'dayjs/locale/nl'

const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
const customParseFormat = require('dayjs/plugin/customParseFormat')
const localizedFormat = require('dayjs/plugin/localizedFormat')
const isToday = require('dayjs/plugin/isToday')

dayjs.extend(isToday)
dayjs.extend(localizedFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

export function isDateAfter(firstDate, secondDate) {
    return dayjs(firstDate).isAfter(secondDate)
}

export function isTodayOrBefore(entryDate) {    
    const currentDate = new Date().toJSON().slice(0, 10)

    return dayjs(entryDate).isSameOrBefore(currentDate)
}

export function backEndDatetimeFormat(localDatetime) {
    return dayjs(localDatetime, ['DD-MM-YYYY HH:mm', 'DD-MM-YYYY'])
        .tz('Europe/Amsterdam')
        .format()
}

export function formatToLocalDutchDate(utcDatetime) {
    if (!utcDatetime) return null
    return dayjs(utcDatetime)
        .locale('nl')
        .format('L')
}

export function formatToLocalDutchTime(utcDatetime) {
    if (!utcDatetime) return '-'
    return dayjs(utcDatetime)
        .locale('nl')
        .format('LT')
}

export function formatToDateTimeString(isoDate) {
    if (!isoDate) return '-'
    return dayjs(isoDate).format('YYYY-MM-DDTHH:mm:ssZ')
}  

export function formatDateForDatapicker(value) {
    return dayjs(value, ['DD-MM-YYYY', 'D-M-YYYY']).format('DD-MM-YYYY')
}

export function getDayOfWeekFromDate(value) {
    return dayjs(value, 'DD-MM-YYYY')
        .locale('en')
        .format('ddd')
}

export function formatUtcDateTimeToLocalDate(utcDateTime) {
    return utcDateTime
        ? dayjs
            .utc(utcDateTime)
            .local()
            .format('DD-MM-YYYY')
        : null
}

export function formatStartDate(date) {
    // Assuming startDate is an object with day, month, and year properties
    const { day, month, year } = date

    const dateObj = dayjs(`${year}-${month}-${day}`)

    return dateObj.format('DD-MM-YYYY')
}

export function extractTimeFromDateTimeString(dateTimeString) {
    const dateTime = dayjs(dateTimeString)

    return dateTime.format('HH:mm')
}

export function validateFullDate(fullDate) {
    return fullDate ? dayjs(fullDate, 'DD-MM-YYYY', true).isValid() : true
}

export function toISODateString(date) {
    const inputDate = dayjs(date, 'DD-MM-YYYY')
    if (date && inputDate.isValid()) {
        return inputDate.toISOString()
    }
    return null
}

export function toISODateStringLastDay(date) {
    const inputDate = dayjs(date, 'DD-MM-YYYY').add(1, 'day')
    if (date && inputDate.isValid()) {
        return inputDate.toISOString()
    }
    return null
}

// convert event datetime backend utc format to local time
export function formatEventDateTimeToLocal(startMoments) {
    if (startMoments) {
        startMoments.forEach((startMoment) => {
            if (!startMoment.modules) return
            startMoment.modules.forEach((module) => {
                if (!module.events || !module.events.length < 0) return
                module.events.forEach((event) => {
                    event.eventDate = formatToLocalDutchDate(event.eventDate)
                    event.startTime = formatToLocalDutchTime(event.startTime)
                    event.endTime = formatToLocalDutchTime(event.endTime)
                })
            })
        })
    }
    return null
}

// combine eventDate with event startTime or event endTime that are accepted by the server
export function combineEventDateTime(course) {
    if (course && course.learningMethod && course.learningMethod.planning) {
        course.learningMethod.planning.startMoments.forEach((startMoment) => {
            if(!startMoment.modules) return
            
            startMoment.modules.forEach((module) => {
                module.events.forEach((event) => {
                    const eventDate = event.eventDate
                    event.eventDate = backEndDatetimeFormat(
                        `${eventDate} ${event.startTime}`
                    )
                    event.startTime = backEndDatetimeFormat(
                        `${eventDate} ${event.startTime}`
                    )
                    event.endTime = backEndDatetimeFormat(
                        `${eventDate} ${event.endTime}`
                    )
                })
            })
        })
        return course
    }
    return null
}

// convert accreditation dates, course startDate & endDate to utc that are accepted by the server
export function formatToUtcBeforeSave(course) {
    if (course.accreditation && course.accreditation.validFrom) {
        course.accreditation.validFrom = backEndDatetimeFormat(
            course.accreditation.validFrom
        )
    }
    if (course.accreditation && course.accreditation.validTo) {
        course.accreditation.validTo = backEndDatetimeFormat(
            course.accreditation.validTo
        )
    }
    if (course.startDate) {
        course.startDate = backEndDatetimeFormat(course.startDate)
    }
    if (course.endDate) {
        course.endDate = backEndDatetimeFormat(course.endDate)
    }

    return null
}

// Used on course create
export function formatCutOfDateToUtcBeforeSave(course) {
    if (course.learningMethod.planning.startMoments) {
        course.learningMethod.planning.startMoments.forEach(startMoment => {
            if (course.productType === 'Incompany' && startMoment.cutOffDate !== null) {
                startMoment.cutOffDate = backEndDatetimeFormat(startMoment.cutOffDate)
            }

            if(course.productType !== 'Incompany') {
                startMoment.cutOffDate = null
            }
        })
    }
    return null
}

// Used for the edit course
export function formatStartmomentCutOfDateToUtcBeforeSave(course, startmoment) {
    if (course.learningMethod.planning.startMoments) {
        if (course.productType === 'Incompany' && startmoment.cutOffDate !== null) {
            startmoment.cutOffDate = backEndDatetimeFormat(startmoment.cutOffDate)
        }
        
        if (course.productType !== 'Incompany' && startmoment.cutOffDate !== null) {
            startmoment.cutOffDate = null
        }
    }
    return null
}

// Used on course duplication
export function formatDuplicatedCourse(course) {
    // General Info data formatting
    course.startDate = formatUtcDateTimeToLocalDate(course.startDate)
    course.endDate = formatUtcDateTimeToLocalDate(course.endDate)


    // Planning data formatting
    course.learningMethod.planning.startMoments.forEach(startmoment => {
        startmoment.startDate = formatStartDate(startmoment.startDate)
        startmoment.cutOffDate = formatUtcDateTimeToLocalDate(startmoment.cutOffDate)
        
        startmoment.modules.forEach(startmomentModule => {
            startmomentModule.events = startmomentModule.events.map(event => ({
                ...event,
                eventDate: formatUtcDateTimeToLocalDate(event.eventDate),
                startTime: extractTimeFromDateTimeString(event.startTime),
                endTime: extractTimeFromDateTimeString(event.endTime)
            }))
        })
    })
    

    // Diploma data formatting
    if(course.accreditation) {
        course.accreditation.validFrom = formatUtcDateTimeToLocalDate(course.accreditation.validFrom)
        course.accreditation.validTo = formatUtcDateTimeToLocalDate(course.accreditation.validTo)

    }
}

// convert from utc to local date
export const formatFromUtcToLocalDate = (course) => {
    if (course.accreditation && course.accreditation.validFrom) {
        course.accreditation.validFrom = formatUtcDateTimeToLocalDate(
            course.accreditation.validFrom
        )
    }
    if (course.accreditation && course.accreditation.validTo) {
        course.accreditation.validTo = formatUtcDateTimeToLocalDate(
            course.accreditation.validTo
        )
    }
    if (course.startDate) {
        course.startDate = formatUtcDateTimeToLocalDate(course.startDate)
    }
    if (course.endDate) {
        course.endDate = formatUtcDateTimeToLocalDate(course.endDate)
    }

    if (course.learningMethod.planning) {
        course.learningMethod.planning.startMoments.forEach((startMoment) => {
            startMoment.startDate = formatUtcDateTimeToLocalDate(startMoment.startDate)
            if (course.productType === 'Incompany' && startMoment.cutOffDate) {
                startMoment.cutOffDate = formatUtcDateTimeToLocalDate(startMoment.cutOffDate)
            }
        })
    }
}

export const formatStartmomentsFromUtcToLocalDate = (course) => {
    if (course.learningMethod.planning) {
        course.learningMethod.planning.startMoments.forEach((startMoment) => {
            startMoment.startDate = formatUtcDateTimeToLocalDate(startMoment.startDate)

            if(startMoment.cutOffDate) {
                startMoment.cutOffDate = formatUtcDateTimeToLocalDate(startMoment.cutOffDate)
            }
        })
    }
}

export const formatBirthDateFromServerToDatePickerFormat = (order) => {
    if (order.customer && order.customer.birthDate)
        order.customer.birthDate = dayjs(
            order.customer.birthDate,
            'YYYY-MM-DD'
        ).format('DD-MM-YYYY')
}
